export interface MetricSeriesInterface {
    name: string;
    series: {
        name: string | number | Date;
        value: number;
    }[] & {processed?: boolean};
}

export class Metric {
    name: string;
    num: number;
    rate?: number;
    total?: number;
    rating?: 'success' | 'danger' | 'warning' | '';
    meta?: any;
    target?: number;
    targetNum?: number;
    critical?: number;
    criticalNum?: number;
    series?: MetricSeriesInterface[];
    seriesMax?: number;
    seriesMin?: number;
    seriesReferenceLines?: {name: string | number; value: number}[];
    change?: {percentage: number; absPercentage: number; direction: 'up' | 'down' | ''; rating?: 'success' | 'warning' | 'danger' | ''};
    value?: any;

    constructor(name: string,
                num: number | {num?: number; rate?: number},
                opts?: {optimal_gt?: number; optimal_lt?: number; critical_gt?: number; critical_lt?: number; meta?: any; series?: MetricSeriesInterface[]; positive_direction?: 'up' | 'down'},
                total?: number,
                value?: any) {
        this.name = name || num && num['name'] || '';
        this.num = typeof num === 'number' ? num : num ? num.num : null;
        this.rate = num && num['rate'] || (total ? this.num / total * 100 : undefined);
        this.total = total;
        this.value = value;
        if ((this.rate || this.rate == 0) && opts && [typeof opts.optimal_lt, typeof opts.optimal_gt, typeof opts.critical_lt, typeof opts.critical_gt].includes('number')) {
            if ((opts.optimal_gt && this.rate >= opts.optimal_gt) || (opts.optimal_lt && this.rate <= opts.optimal_lt)) this.rating = 'success';
            else if ((opts.critical_gt && this.rate >= opts.critical_gt) || (opts.critical_lt && this.rate <= opts.critical_lt)) this.rating = 'danger';
            else this.rating = 'warning';
        }
        if (opts) {
            this.target = opts.optimal_gt || opts.optimal_lt || undefined;
            this.targetNum = this.target && total ? Math.round(this.target * total / 100) : undefined;
            this.critical = opts.critical_gt || opts.critical_lt || undefined;
            this.criticalNum = this.critical && total ? Math.round(this.critical * total / 100) : undefined;
            this.meta = opts.meta || (typeof num !== 'number' && num) || undefined;
            this.series = opts.series || null;
        }
        if (total && this.series) {
            this.seriesMax = this.getSeriesMinMax(total).max;
            this.seriesMin = this.getSeriesMinMax(total).min;
        }
        if (this.series) {
            this.seriesReferenceLines = [this.targetNum, this.criticalNum].filter(x => !!x || x == 0).map(x => ({name: x, value: x}));
            const base = this.series[0].series[0].value || 0;
            const change = (this.num - base) / base * 100;
            this.change = {
                percentage: change,
                absPercentage: Math.abs(change),
                direction: change > 0 ? 'up' : change < 0 ? 'down' : '',
            };
            if (opts.optimal_gt || opts.critical_lt || (opts.positive_direction && opts.positive_direction == 'up')) {
                this.change.rating = this.change.direction == 'up' ? 'success' : this.change.direction == 'down' ? 'danger' : '';
            } else if (opts.optimal_lt || opts.critical_gt || (opts.positive_direction && opts.positive_direction == 'down')) {
                this.change.rating = this.change.direction == 'down' ? 'success' : this.change.direction == 'up' ? 'danger' : '';
            }
        }
    }

    getSeriesMinMax(total: number): {min?: number; max?: number} {
        if (!this.series) return {min: undefined, max: undefined};
        let max = Math.max.apply(null, this.series.map(s => Math.max.apply(null, s.series.map(x => x.value))));
        let min = Math.min.apply(null, this.series.map(s => Math.min.apply(null, s.series.map(x => x.value))));
        if (this.target) {
            const target = total * this.target / 100;
            max = Math.max(max, target);
            min = Math.min(min, target);
        }
        if (this.critical) {
            const critical = total * this.critical / 100;
            max = Math.max(max, critical);
            min = Math.min(min, critical);
        }
        return {
            max: Math.ceil(max),
            min: Math.floor(min),
        };
    }
}
